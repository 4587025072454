import { Input } from "@rneui/themed"
import { useTranslation } from "react-i18next"
import { AddressComponentProps } from "../../../@types/components/address"
import { useAppDispatch } from "../../redux/hooks"
import { setPhysicianOfficeAddressLine1, setPhysicianOfficeAddressLine2, setPhysicianOfficeAddressPostalCode, setPhysicianOfficeCity, setPhysicianOfficeCountry, setPhysicianOfficeStateProvince } from "../../redux/medicalProfileState"
import { styles } from "./styles"


export const Address = (props: AddressComponentProps) => {

	const { office_address, index, medErrors } = props

	const dispatch = useAppDispatch()

	const { t } = useTranslation(["translation", "screens"])

	const physicianProperty = `physician${index + 1}`


	return (
		<>
			<Input
				style={styles.input}
				label={t("officeAddressLine1")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={office_address.address_line1}
				onChangeText={(address) => dispatch(setPhysicianOfficeAddressLine1({index, address}))}
				errorMessage={medErrors?.[`${physicianProperty}_address_line1`]?.join("\n")}/>
			<Input
				style={styles.input}
				label={t("officeAddressLine2")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={office_address.address_line2}
				onChangeText={(address) => dispatch(setPhysicianOfficeAddressLine2({index, address}))}
				errorMessage={medErrors?.[`${physicianProperty}_address_line2`]?.join("\n")}/>

			<Input
				style={styles.input}
				label={t("city")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={office_address.city}
				onChangeText={(city) => dispatch(setPhysicianOfficeCity({index, city}))}
				errorMessage={medErrors?.[`${physicianProperty}_city`]?.join("\n")}/>
			<Input
				style={styles.input}
				label={t("state")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={office_address.state_province}
				onChangeText={(state_province) => dispatch(setPhysicianOfficeStateProvince({state_province, index}))}
				errorMessage={medErrors?.[`${physicianProperty}_state_province`]?.join("\n")}/>
			<Input
				style={styles.input}
				label={t("zipCode")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={office_address.postal_code}
				onChangeText={(code) => dispatch(setPhysicianOfficeAddressPostalCode({index, code}))}
				errorMessage={medErrors?.[`${physicianProperty}_postal_code`]?.join("\n")}/>
			<Input
				style={styles.input}
				label={t("country")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={office_address.country}
				onChangeText={(country) => dispatch(setPhysicianOfficeCountry({index, country}))}
				errorMessage={medErrors?.[`${physicianProperty}_country`]?.join("\n")}/>
		</>
	)
}
