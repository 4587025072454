import { CheckBox, Text } from "@rneui/themed"
import * as Network from "expo-network"
import { useEffect } from "react"
import { View } from "react-native"
import { Feather } from "@expo/vector-icons"
import { getDiseases, setSelectedDisease } from "../../redux/diseaseState"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { AppState } from "../../redux/store"
import { styles } from "./styles"
import { useTheme } from "@rneui/themed"
import { useTranslation } from "react-i18next"
import { showToast } from "../../../toast"
import { updateActiveDisease } from "../../redux/authState"
import { DiseaseType } from "../../../@types/redux/disease"
import { useRoute } from "@react-navigation/native"


export const DiseasePicker = () => {

	const { theme } = useTheme()
	const { t } = useTranslation()

	const dispatch = useAppDispatch()
	const availableDiseases = useAppSelector((state: AppState) => state.diseaseState.available)
	const selectedDisease = useAppSelector((state: AppState) => state.diseaseState.selected)
	const { id: userId } = useAppSelector((state: AppState) => state.authState.authState)

	useEffect(() => {
		dispatch(getDiseases())
	}, [])

	const route = useRoute()


	const handleSetSelectedDisease = async (disease: DiseaseType) => {

		const { isConnected, isInternetReachable } = await Network.getNetworkStateAsync()
		console.log("route name: ", route.name)
		if (route.name == "Eligibility") {
			dispatch(setSelectedDisease(disease))
			return
		}
		if (userId && !isConnected && !isInternetReachable) {
			showToast({ message: "Must be connected to internet to change disease. Please try again when you are connected to the internet", kind: "failure" })
			return
		}
		if (userId) {
			try {
				dispatch(updateActiveDisease({ disease: disease.id })).unwrap()
				dispatch(setSelectedDisease(disease))
				showToast({
					message: "Updated disease successfully!",
					kind: "success"
				})
			} catch (err) {
				showToast({
					message: `Error updating selected disease. Error: ${err}`,
					kind: "failure"
				})
			}
		} else {
			dispatch(setSelectedDisease(disease))
		}
	}

	return (
		<View style={[styles.diseasePickerContainer, { backgroundColor: theme.colors.white }]}>
			<Text style={{ textAlign: "center", padding: 5 }} >{t("selectDiseaseMessage")}</Text>
			<View style={styles.checkboxContainer}>
				{
					availableDiseases.map(disease => {
						return (
							<CheckBox key={disease.id}
								center
								title={disease.name}
								checked={disease.id == selectedDisease?.id}
								checkedIcon={
									<Feather name='check-circle' size={25} color='green' />
								}
								uncheckedIcon={
									<Feather
										name="circle"
										color="grey"
										size={25}
									/>
								}
								onPress={() => handleSetSelectedDisease(disease)}
							/>
						)
					})
				}
			</View>
		</View>
	)
}
