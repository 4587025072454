import { Text } from "@rneui/themed"
import { AppState } from "../../redux/store"
import { useAppSelector } from "../../redux/hooks"
import { BasePage } from "../../components/BasePage/BasePage"
import { LoadingSpinner } from "../../components/Loading/LoadingSpinner"




export const HomeScreen = () => {
	const authState = useAppSelector((state: AppState) => state.authState.authState)
	const diseaseState = useAppSelector((state: AppState) => state.diseaseState)
	const assessmentsLoading = useAppSelector((state: AppState) => state.assessmentState.isLoading)

	const currentDisease = authState.eligibilityStatuses?.find(
		(status) => (status.disease == diseaseState.selected?.id) && (status.status == "approved")
	)

	return (
		<BasePage>
			<LoadingSpinner isLoading={assessmentsLoading} message="Loading..." />
			<Text style={{textAlign: "center"}}>Welcome to the Myositis Patient Centered Tele-Research Study</Text>
		</BasePage>
	)
}
