import { useFocusEffect, useNavigation } from "@react-navigation/native"
import { useCallback, useState } from "react"
import { View } from "react-native"
import type {
	AssessmentsScreenNavigationProp,
	QuestionaireScreenProps,
} from "../../../@types/navigation/HomeNavigator"
import type { QuestionaireType } from "../../../@types/redux/questionaire"
import { filterObject, removeFalseyValues } from "../../../util"
import { LoadingSpinner } from "../../components/Loading/LoadingSpinner"
import { QuestionaireComponent } from "../../components/Questionare/Questionaire"
import {
	deletePreviousAssessmentQuestionaireAnswers,
	resetErrors as clearAnswerErrors,
	syncAnswersForAssessmentQuestionaire,
} from "../../redux/answerState"
import {
	setQuestionaireIsDirty,
	setQuestionaireSubmissionStatus,
	syncAssessmentQuestionaire,
} from "../../redux/assessmentState"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import type { AppState } from "../../redux/store"
import {
	alertForUnansweredQuestions,
	checkAllQuestionsHaveAnswers,
	submitAnswer,
} from "../util"
import { SubmitAnswerProps } from "../../../@types/screens/util"
import { useToast } from "react-native-toast-notifications"
import { BasePage } from "../../components/BasePage/BasePage"
import { sleep } from "../../components/util"

export const QuestionaireScreen = ({ route }: QuestionaireScreenProps) => {
	const toast = useToast()
	const navigation = useNavigation<AssessmentsScreenNavigationProp>()

	const { questionaireIndex, assessmentIndex } = route.params

	const [questionaire, setQuestionaire] = useState<
    QuestionaireType | undefined
  >()
	const [unansweredQuestions, setUnansweredQuestions] = useState<
    string[]
  >([])

	const dispatch = useAppDispatch()

	const answerState = useAppSelector((state: AppState) => state.answerState)
	const assessmentState = useAppSelector(
		(state: AppState) => state.assessmentState
	)

	const assessment = assessmentState.assessments[assessmentIndex]
	const assessmentId = assessment?.id
	const questionaireId = assessment.questionaires[questionaireIndex]?.id

	useFocusEffect(
		useCallback(() => {
			let isActive = true

			if (isActive) {
				const assessment = assessmentState.assessments.find(
					(assessment) => assessment?.id === assessmentId
				)
				const foundQuestionaire = assessment?.questionaires.find(
					(questionaire) => questionaire?.id === questionaireId
				)
				setQuestionaire(foundQuestionaire)
			}

			return () => {
				isActive = false
				setQuestionaire(undefined)
			}
		}, [questionaireId, assessmentId, assessmentState])
	)

	const dismissErrors = () => {
		dispatch(clearAnswerErrors())
	}

	const syncQuestionsAndAnswers = async () => {
		await dispatch(syncAssessmentQuestionaire({ questionaireIndex, assessmentIndex })).unwrap()
		const res = await dispatch(
			syncAnswersForAssessmentQuestionaire({
				questionaireId,
				assessmentId,
				assessmentIndex,
				questionaireIndex,
			})
		).unwrap()
		console.log("done syncing questionaire and answers")
		return res
	}

	const handleQuestionaireSubmit = async () => {
		if (questionaire) {
			const { allAnswered, unansweredQuestionPaths } = checkAllQuestionsHaveAnswers(
				{ questionaire, answerState, questionaireIndex, assessmentIndex }
			)
			setUnansweredQuestions(unansweredQuestionPaths)
			if (!allAnswered) {
				alertForUnansweredQuestions()
				return
			}
			if (assessment) {
				const answers = await syncQuestionsAndAnswers()

				await dispatch(
					deletePreviousAssessmentQuestionaireAnswers({
						assessmentId,
						assessmentIndex,
						questionaireId,
						questionaireIndex,
					})
				)

				// do recursive answer state query here
				const answerSubmissions = answers?.map(async (answer, i) => {
					const filteredAnswer = filterObject(answer, removeFalseyValues)

					const data: SubmitAnswerProps = {
						answer: filteredAnswer,
						parentAnswerId: filteredAnswer.parent_answer,
						questionPath: `[${i}]`,
						assessmentIndex,
						questionaireIndex,
						questionState: questionaire.questions,
					}
					console.log("data for submit answer: ", data)
					return await submitAnswer(data)
				})
				const results = await Promise.allSettled(answerSubmissions)
				console.log("results from submitting: ", results)
				const wasSubmittedSuccessfully = results.every(
					(result) => result.status === "fulfilled"
				)
				if (wasSubmittedSuccessfully) {
					toast.show("Questionnaire successfuly submitted!", {
						type: "success",
						placement: "bottom",
						duration: 8000,
						animationType: "slide-in",
					})
					console.log("setting questionaire status to true", assessmentIndex, questionaireIndex)
					dispatch(
						setQuestionaireSubmissionStatus({
							assessmentIndex,
							questionaireIndex,
							submitted: true,
						})
					)
					dispatch(
						setQuestionaireIsDirty({
							assessmentIndex,
							questionaireIndex,
							isDirty: false,
						})
					)
					navigation.navigate("Assessments")
				}
			}
		}
	}

	// The function to update the unansweredQuestions state
	const removeUnansweredQuestion = (answeredQuestionPath: string) => {
		setUnansweredQuestions(prevQuestions => prevQuestions.filter(questionPath => questionPath !== answeredQuestionPath))
	}

	return (
		<BasePage>
			<LoadingSpinner isLoading={answerState.isLoading} message="Loading..." />
			<QuestionaireComponent
				questionaire={
					assessmentState.assessments[assessmentIndex].questionaires[
						questionaireIndex
					]
				}
				questionaireIndex={questionaireIndex}
				isDirty={
					assessmentState.assessments[assessmentIndex].questionaires[
						questionaireIndex
					].isDirty
				}
				assessmentId={assessmentId}
				assessmentIndex={assessmentIndex}
				unansweredQuestions={unansweredQuestions}
				onSubmitCallback={handleQuestionaireSubmit}
				removeUnansweredQuestion={removeUnansweredQuestion}
			/>
		</BasePage>
	)
}
