import { Button, Text } from "@rneui/themed"
import { View, Platform } from "react-native"
import SignatureScreen, {
	SignatureViewRef,
} from "react-native-signature-canvas"
import SignatureCanvas from "react-signature-canvas"
import { useRef, useState } from "react"
import { ConsentFormProps } from "../../../@types/components/consent"
import { styles } from "./styles"
import { useTranslation } from "react-i18next"
import { useTheme } from "@rneui/themed"
import { showToast } from "../../../toast"


export const ConsentForm = (props: ConsentFormProps) => {

	const { 
		handleSignatureConfirmCallback,
		handleDownloadDocument, 
		hasViewedConsentDocument
	} = props

	const { theme } = useTheme()

	const { t } = useTranslation()

	const [canvasSignatureIsEmpty, setCanvasSignatureIsEmpty] = useState<boolean>(true)

	const mobileRef = useRef<SignatureViewRef>(null)
	const webRef = useRef<SignatureCanvas>(null)
	// TODO figure out how to use existing signature as default value
	const signatureRef = useRef("")

	const handleSignature = (signature: string): string => {
		signatureRef.current = signature
		return signature
	}

	const handleClear = () => {
		mobileRef.current?.clearSignature()
		webRef.current?.clear()
		signatureRef.current = ""
		setCanvasSignatureIsEmpty(true)
		const message = t("signatureClear")
		showToast({ message, kind: "information" })
	}

	const handleEnd = () => {
		mobileRef.current?.readSignature()
		setCanvasSignatureIsEmpty(false)
	}

	const handleConfirm = () => {
		const signatureString = Platform.OS == "web" ? webRef.current?.toDataURL() : signatureRef.current

		const webCanvasSignatureIsEmpty = webRef.current?.isEmpty()

		if ((!(Platform.OS == "web") && canvasSignatureIsEmpty) || (Platform.OS == "web" && webCanvasSignatureIsEmpty)) {
			const message = t("signToContinue")
			showToast({ message, kind: "failure" })
		} else {		
			handleSignatureConfirmCallback(signatureString || "")
			const message = t("Signature confirmed!")
			showToast({ message, kind: "success" })
		}
	}

	const style = ".m-signature-pad--footer {display: none; margin: 0px;}"

	if (!(Platform.OS == "web")) { // platform is mobile
		return (
			<View style={[styles.container, {backgroundColor: theme.colors.white}]} >
				<Text style={styles.consentFormText} hyperlink onPress={handleDownloadDocument}>{t("clickForConsentForm")}</Text>
				<Text style={styles.signBelowText} >{t("signBelow")}</Text>
				<SignatureScreen
					ref={mobileRef} 
					onOK={handleSignature}
					onEnd={handleEnd} 
					webStyle={style}
					style={{width: 50, height: 50, minHeight: 300, minWidth: 300}}
				/>
				<View style={styles.buttonsContainer}>
					<View style={styles.buttonContainer}>
						<Button title="Clear" onPress={handleClear} />
					</View>
					<View style={styles.buttonContainer}>
						<Button 
							title="Confirm" 
							onPress={handleConfirm} 
							disabled={!hasViewedConsentDocument}
						/>
					</View>
				</View>
			</View>
		)
	} else { // platform is web
		return (
			<View style={styles.container} >
				<Text style={styles.consentFormText} onPress={handleDownloadDocument} hyperlink>{t("clickForConsentForm")}</Text>
				<Text style={styles.signBelowText} >{t("signBelow")}</Text>
				<SignatureCanvas
					ref={webRef} 
					backgroundColor="rgb(211,211,211)" 
					canvasProps={{width: "380%", height: 400, defaultValue: signatureRef.current}}
				/>
				<Text style={{ marginTop: 10, textAlign: "center", color: "red" }}>You must read the consent document before confirming your signature</Text>
				<View style={[styles.buttonsContainer]}>
					<View style={[styles.buttonContainer, {marginRight: 8}]}>
						<Button title="Clear" onPress={handleClear} />
					</View>
					<View style={styles.buttonContainer}>
						<Button 
							title="Confirm" 
							onPress={handleConfirm} 
							disabled={!hasViewedConsentDocument}
						/>
					</View>
				</View>
			</View>
		)
	}
}
