import { useState } from "react"
import { useTranslation } from "react-i18next"
import { MedicalProfileScreenNavigationProp, MedicalProfileScreenProps } from "../../../../@types/navigation/AuthNavigator"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { styles } from "./styles"
import { Button, Text } from "@rneui/themed"
import { AppState } from "../../../redux/store"
import { View, ScrollView, SafeAreaView } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { ConsentForm } from "../../../components/Consent/Consent"
import { showToast } from "../../../../toast"
import { createMedicalProfile, setLoadingState, setMedicalReleaseSignature } from "../../../redux/medicalProfileState"
import { MedicalProfile } from "../../../components/MedicalProfile/MedicalProfile"
import { downloadDocument, getDiseaseDocuments } from "../../../redux/documentState"
import { LoadingSpinner } from "../../../components/Loading/LoadingSpinner"
import { StyleSheet } from "react-native"
import { resetErrors } from "../../../redux/medicalProfileState"


export const MedicalProfileScreen = ({ route }: MedicalProfileScreenProps) => {


	const { userId } = route.params

	const navigation = useNavigation<MedicalProfileScreenNavigationProp>()
	const { t } = useTranslation(["translation", "screens"])
	const [hasViewedDocument, setHasViewedDocument] = useState(false)

	const dispatch = useAppDispatch()
	const medProfile = useAppSelector((state: AppState) => state.medicalProfileState.profile)
	const medProfileLoading = useAppSelector((state: AppState) => state.medicalProfileState.isLoading)
	const documentStateLoading = useAppSelector((state: AppState) => state.documentState.isLoading)
	const disease = useAppSelector((state: AppState) => state.diseaseState.selected?.id)?.toString()

	let dob = ""
	if (medProfile.date_of_birth) {
		// Create a Date object if necessary
		const dateOfBirth = medProfile.date_of_birth instanceof Date ? medProfile.date_of_birth : new Date(medProfile.date_of_birth)
		// Check if the date is valid
		if (!isNaN(dateOfBirth.getTime())) {
			// Format the date, excluding the day of the week
			dob = dateOfBirth.toDateString().split(" ").slice(1).join(" ")
		}
	}


	


	const handleCreateMedicalProfile = async () => {
		try {
			if (!medProfile.medical_release_signature) {
				console.log("dont have signature")
				showToast({ message: t("signMedForm"), kind: "failure" })
				window.scrollTo({top: 0, behavior: "instant"})
				return
			}
			
			dispatch(resetErrors())
			const medProfileResponse = await dispatch(createMedicalProfile(userId)).unwrap()
			console.log("medical profile create response: ", medProfileResponse)
			showToast({ message: t("successCreatingMedProfile"), kind: "success"})
			navigation.navigate("Login")
		} catch (error) {
			console.log("Error submitting medical profile: ", error)
			showToast({ message: t("errorCreatingMedProfile"), kind: "failure"})
			window.scrollTo({top: 0, behavior: "instant"})
		}
	}

	const handleDownloadDocument = async () => {
		console.log("starting to download the consent form")

		try {
			const res = await dispatch(getDiseaseDocuments({ kind: "consent_form", disease })).unwrap()
			console.log(res)
			// TODO might need to handle downloading multiple documents in the future
			const { file, filename } = res[0]
			await dispatch(downloadDocument({ filename, fileUrl: file }))
			setHasViewedDocument(true)
		} catch (error) {
			console.log("error downloading document: ", error)
		}

		console.log("downloading consent form")
	}
	console.log("med profile loading: ", medProfileLoading)
	console.log("document loading: ", documentStateLoading)
	dispatch(setLoadingState(false))

	return (
		<SafeAreaView>
			<ScrollView style={styles.container}>
				<LoadingSpinner
					isLoading={medProfileLoading || documentStateLoading} 
					message={"Loading..."} 
				/>
				<MedicalProfile handleSubmit={handleCreateMedicalProfile} userId={userId} shouldShowSubmitButton={false}/>
				<View style={ styles1.container }>
					<Text style={styles1.header}>{`${t("iAuthorize")} ${medProfile.physicians.map(p => `${p.first_name} ${p.last_name}`).join(", ")} ${t("releaseInfo")}`}</Text>
					<View style={ styles1.section }>
						<Text style={styles1.title}>{`${medProfile.first_name} ${medProfile.last_name}`}</Text>
						<Text style={styles1.detail}>{`${t("bornOn")} ${dob}`}</Text>
					</View>
					<View style={ styles1.section }>
						<Text style={styles1.title}>{"Address"}</Text>
						<Text style={styles1.detail}>{"Rohit Aggarwal, MD, MS\nBST S726\n200 Lothrop St\nPittsburgh, PA, 15261"}</Text>
						<Text style={styles1.detail}>{"Phone: "}</Text>
						<Text style={styles1.detail}>{"Fax: "}</Text>
					</View>
					<View style={ styles1.section }>
						<Text style={styles1.title}>{`${t("forPurposeOf")}`}</Text>
						<Text style={styles1.detail}>{`${t("myositisStudy")}`}</Text>
					</View>
					<View style={ styles1.section }>
						<Text style={styles1.title}>{`${t("kindsOfRecords")}`}</Text>
						<Text style={styles1.detail}>{`${t("outpatient")}, ${t("physician")}`}</Text>
						<Text style={styles1.detail}>{t("datesOfService")}</Text>
						<Text style={styles1.detail}>{t("infoToBeReleased")}</Text>
						<Text style={styles1.detail}>{t("consults")}</Text>
						<Text style={styles1.detail}>{`${t("labReports")} (CPK, Adolase, CBC, Myositis antibodies, LFT, BMP)`}</Text>
						<Text style={styles1.detail}>{`${t("medHistory")} & ${t("physicalExam")}`}</Text>
					</View>
				</View>

				<ConsentForm 
					handleSignatureConfirmCallback={(signature: string) => dispatch(setMedicalReleaseSignature(signature))}
					handleDownloadDocument={handleDownloadDocument}
					existingSignature={medProfile.medical_release_signature}
					hasViewedConsentDocument={hasViewedDocument}
				/>

			</ScrollView>
			<Button
				title={t("Submit") || "Submit"}
				onPress={handleCreateMedicalProfile}
				buttonStyle={styles.button}
				titleStyle={styles.buttonText}
			/>
		</SafeAreaView>
	)
}

const styles1 = StyleSheet.create({
	container: {
		padding: 20,
		backgroundColor: "#fff", 
	},
	header: {
		fontSize: 22,
		fontWeight: "bold",
		marginBottom: 10,
		color: "#000", 
	},
	section: {
		marginBottom: 20, 
	},
	title: {
		fontSize: 18,
		fontWeight: "bold",
		marginBottom: 5,
		color: "#333", 
	},
	detail: {
		fontSize: 16,
		lineHeight: 24, 
		color: "#555", 
	},
	highlight: { 
		color: "#0066cc",
		textDecorationLine: "underline",
	},
  
	icon: {
		width: 20,
		height: 20,
		marginRight: 5,
	},
})
