export default {
	translation: {
		hello: "Hello",
		languageSelector: "Select Your Language",
		phoneNumber: "Phone Number",
		clickToReadConsent: "Click here to read the Full Consent Form",
		signBelow: "Please sign below using your finger",
		gender: "Gender",
		genderOptions: ["Male", "Female"],
		race: "Race",
		raceOptions: [
			"White or Caucasian",
			"Black or African-American",
			"Asian",
			"Native Hawaiian or other Pacific Islander",
			"American Indian or Alaska Native",
			"Other",
		],
		ethnicity: "Ethnicity",
		ethnicityOptions: ["Hispanic", "Non-Hispanic"],
		employmentStatus: "Employment Status",
		employmentStatusOptions: [
			"Working",
			"Retired",
			"Homemaker",
			"Student",
			"Disabled",
			"Unemployed",
			"Other",
		],
		username: "Enter your username",
		password: "Create password",
		passwordAgain: "Confirm password",
		email: "Enter your email",
		firstName: "First Name",
		lastName: "Last Name",
		socialSecurityNumber: "Social Security Number",
		occupationTitle: "Occupation Title",
		whereHearAboutStudy: "Where did you hear about this study?",
		physicianFirstName: "Physician's First Name",
		physicianLastName: "Physician's Last Name",
		physicianSpecialty: "Physician's Specialty",
		officeAddressLine1: "Physician's Office Address Line 1",
		officeAddressLine2: "Physician's Office Address Line 2",
		country: "Country",
		city: "City",
		state: "State",
		zipCode: "Zip Code",
		physiciansPhoneNumber: "Physician's Phone Number",
		removePhysician: "Remove Physician",
		faxNumber: "Fax Number",
		addPhysician: "Add Physician",
		dateOfBirth: "Date Of Birth",
		userAccountInformation: "Create Account",
		medicalInformation: "Medical Information",
		signUpNewUserPending: "Creating account...",
		signUpNewUserFulfilled: "Account created successfully!",
		signUpNewUserRejected:
			"Something went wrong creating your account. Please try again.",
		logUserInPending: "Logging in...",
		logUserInFulfilled: "Logged in sucessfully!",
		logUserInRejected: 
			"The username or password you entered doesn't match our records. Please double-check and try again, or use the 'Forgot password?' link to reset your password.",
		createMedicalProfilePending: "Creating medical profile...",
		createMedicalProfileFulfilled: "Medical Profile created successfully!",
		createMedicalProfileRejected:
			"Something went wrong creating your medical profile. Please try again.",
		documentUploadFulfilled: "Document uploaded successfully.",
		documentUploadRejected:
			"Something went wrong uploading your document. Please try again.",
		continue: "Continue",
		selectDiseaseToContinue: "Please select a disease to continue.",
		signToContinue: "Please sign to continue",
		clickForConsentForm: "Click here to read the consent form.",
		selectDiseaseMessage: "Select the disease below that is the most relevant for you.",


		iAuthorize: "I authorize",
		releaseInfo: "to release information from the records of: ",
		bornOn: "Born on: ",
		to: "to ",
		phone: "Phone: ",
		fax: "Fax: ",
		forPurposeOf: "For the purpose of: ",
		myositisStudy: "Myositis research (My Pacer study)",
		kindsOfRecords: "Types of records to be released: ",
		outpatient: "Outpatient",
		physician: "Physician Office/Clinic",
		datesOfService: "Dates of service: Initial visit and last 1 year clinical notes and labs; any muscle or skin biopsy, EMG, CT scan, MRI, Echo, Myositis antibodies, LFT, BMP",
		infoToBeReleased: "Specific information to be released: ",
		consults: "Consults",
		labReports: "Laboratory Reports/Tests ",
		medHistory: "Medical History",
		physicalExam: "Physical Exam",
		signMedForm: "Please sign the form to continue.",
		errorCreatingMedProfile: "There was an error creating your medical profile",
		successCreatingMedProfile: "Medical Profile successfully created!"
	},
}
