import * as _ from "lodash"
import { useEffect, useState } from "react"
import { QuestionProps } from "../../../@types/components/question"
import { AnswerType } from "../../../@types/redux/Answer"
import { useAppSelector } from "../../redux/hooks"
import { AppState } from "../../redux/store"
import { makeRootPath } from "../util"
import { getSavedAnswer, getSubcomponent } from "./util"
import { View } from "react-native"
import { ExtraQuestions } from "./Subcomponents/ExtraQuestions/ExtraQuestions"
import { ChildQuestions } from "./Subcomponents/ChildQuestions/ChildQuestions"
import { Card } from "@rneui/themed"
import { ExtraQuestionsDivider } from "../ExtraQuestionsDivider/ExtraQuestionsDivider"
import { Pictures } from "../Pictures/Pictures"

export const QuestionComponent = (props: QuestionProps) => {
	const {
		question_type,
		prompt,
		assessmentId,
		assessmentIndex,
		questionaireId,
		questionaireIndex,
		questionPath,
		extra_questions,
		unansweredQuestions,
		removeUnansweredQuestion,
		pictures,
		should_use_previous_answers
	} = props

	const [savedAnswer, setSavedAnswer] = useState<AnswerType | undefined>()
	const [shouldRenderAddButton, setShouldRenderAddButton] = useState(false) // Track if the button should be shown

	const answerState = useAppSelector((state: AppState) => state.answerState)


	const answerPath = `${makeRootPath(
		assessmentIndex,
		questionaireIndex
	)}${questionPath}`
		.replaceAll("questions", "answers")
		.replaceAll(".child_question", "")
	

	const rootQuestionPath = `${makeRootPath(
		assessmentIndex,
		questionaireIndex
	)}${questionPath}`
		.replaceAll(".child_question", "")

	const isUnanswered = unansweredQuestions?.find(
		(question) => question === rootQuestionPath
	)


	useEffect(() => {
		if (assessmentId) {
			const answer = getSavedAnswer({ 
				assessmentIndex, 
				questionaireIndex, 
				questionPath, 
				answerState, 
				shouldFallbackToPreviousAnswer: should_use_previous_answers
			})
			if (answer) {
				removeUnansweredQuestion(rootQuestionPath)
				setSavedAnswer(answer)
			}
		} else {
			const answer = getSavedAnswer({ 
				assessmentIndex, 
				questionaireIndex, 
				questionPath, 
				answerState, 
				shouldFallbackToPreviousAnswer: should_use_previous_answers
			})
			if (answer) {
				removeUnansweredQuestion(rootQuestionPath)
				setSavedAnswer(answer)
			}
		}
	}, [answerState, assessmentId, questionaireId])

	return (
		<Card
			containerStyle={
				isUnanswered
					? { borderColor: "red", borderWidth: 2 }
					: { margin: 0, marginBottom: 16 }
			}
		>
			<Card.Title>{prompt}</Card.Title>
			{
				pictures?.length ? <Pictures pictures={pictures}/> : null
			}

			<View>{getSubcomponent(question_type, savedAnswer, props)}</View>
			<View>
				<View>
					<ChildQuestions 
						savedAnswer={savedAnswer} 
						{...props} 
						onChildQuestionsRendered={setShouldRenderAddButton}
					/>
				</View>
				{extra_questions && extra_questions.length ? (
					<ExtraQuestionsDivider />
				) : null}
				<View>{<ExtraQuestions shouldRenderAddButton={shouldRenderAddButton} savedAnswer={savedAnswer} {...props} />}</View>
			</View>
		</Card>
	)
}
