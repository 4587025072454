import { useState } from "react"
import {
	SafeAreaView,
	View,
	Text,
	TouchableHighlight,
	StyleSheet,
} from "react-native"
//@ts-ignore
import { Timer } from "react-native-stopwatch-timer"

export const TimerComponent = () => {
	const [isTimerStart, setIsTimerStart] = useState(false)
	const [timerDuration, setTimerDuration] = useState(30000)
	const [resetTimer, setResetTimer] = useState(false)

	return (
		<SafeAreaView style={styles.container}>
			<View style={styles.container}>
				<View style={styles.sectionStyle}>
					<Timer
						totalDuration={timerDuration}
						msecs
						//Time Duration
						start={isTimerStart}
						//To start
						reset={resetTimer}
						//To reset
						options={options}
						//options for the styling
						handleFinish={() => {
							setIsTimerStart(false)
							setResetTimer(true)
							alert("Finished!")
						}}
					/>
					<TouchableHighlight
						onPress={() => {
							setIsTimerStart(!isTimerStart)
							setResetTimer(false)
						}}
					>
						<Text style={styles.buttonText}>
							{!isTimerStart ? "START" : "STOP"}
						</Text>
					</TouchableHighlight>
					<TouchableHighlight
						onPress={() => {
							setIsTimerStart(false)
							setResetTimer(true)
						}}
					>
						<Text style={styles.buttonText}>RESET</Text>
					</TouchableHighlight>
				</View>
			</View>
		</SafeAreaView>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: 10,
		justifyContent: "center",
		alignItems: "center",
		marginBottom: 50,
	},
	title: {
		textAlign: "center",
		fontSize: 20,
		fontWeight: "bold",
		padding: 20,
	},
	sectionStyle: {
		flex: 1,
		marginTop: 32,
		alignItems: "center",
		justifyContent: "center",
	},
	buttonText: {
		fontSize: 20,
		marginTop: 10,
	},
})

const options = {
	container: {
		backgroundColor: "#007AFF",
		padding: 5,
		borderRadius: 5,
		width: 200,
		alignItems: "center",
	},
	text: {
		fontSize: 25,
		color: "#FFF",
		marginLeft: 7,
	},
}
