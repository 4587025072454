import { useEffect, useState } from "react"
import { View } from "react-native"
import { QuestionSubcomponentProps } from "../../../../../@types/components/question"
import { AnswerType } from "../../../../../@types/redux/Answer"
import { useAppSelector } from "../../../../redux/hooks"
import { AppState } from "../../../../redux/store"
import { QuestionComponent } from "../../Question"


export const ChildQuestions = (props: QuestionSubcomponentProps) => {

	const {
		assessmentId,
		assessmentIndex,
		questionPath,
		questionaireId,
		questionaireIndex,
		should_show_all_questions,
		savedAnswer,
		follow_up_questions,
		onChildQuestionsRendered
	} = props

	const [childQuestionsToRender, setChildQuestionsToRender] = useState<number[]>([])
	const assessmentState = useAppSelector((state: AppState) => state.assessmentState)

	useEffect(() => {
		const childQuestions = checkChildQuestions(savedAnswer)
		setChildQuestionsToRender(childQuestions)

		const allQuestions = follow_up_questions.reduce((acc: number[], _, i) => {
			acc.push(i)
			return acc
		}, [])

		if (should_show_all_questions) setChildQuestionsToRender(allQuestions)
		
		// Notify parent component if any child questions are rendered
		if (onChildQuestionsRendered) {
			onChildQuestionsRendered(childQuestions.length > 0)
		}
	}, [savedAnswer, assessmentState])

	const checkChildQuestions = (foundAnswer: AnswerType | undefined) => {
		const followUpToRender = follow_up_questions.reduce((needToRender: number[], followUpQuestion, i) => {
			if (
				followUpQuestion.triggering_choice?.includes(foundAnswer?.select_one_content || -1) 
					|| 
				followUpQuestion.triggering_choice?.some(choice => foundAnswer?.select_multiple_content?.includes(choice))
			) {
				needToRender.push(i)
			}
			return needToRender
		}, [])
		return followUpToRender
	}

	return (
		<View>
			{
				follow_up_questions.map((question, index) => {
					if (childQuestionsToRender.includes(index)) {
						return (
							<QuestionComponent
								key={index}
								assessmentId={assessmentId}
								assessmentIndex={assessmentIndex}
								questionPath={`${questionPath}.follow_up_questions[${index}].child_question`}
								questionaireId={questionaireId}
								questionaireIndex={questionaireIndex}
								questionaireIsDirty
								isExtra={false}
								should_show_all_questions={should_show_all_questions}
								{...question.child_question}
								unansweredQuestions={props.unansweredQuestions}
								removeUnansweredQuestion={props.removeUnansweredQuestion}
							/>
						)
					}
				})
			}
		</View>
	)
}
