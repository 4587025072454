import { useRef, useState } from "react"
import { Platform, View } from "react-native"
import MobileDatePicker from "@react-native-community/datetimepicker"
import { QuestionSubcomponentProps } from "../../../../../@types/components/question"
import { SetAnswerOfflineParams } from "../../../../../@types/redux/Answer"
import { setAnswerOffline } from "../../../../redux/answerState"
import { useAppDispatch } from "../../../../redux/hooks"
import { styles } from "../../styles"
import { setQuestionaireIsDirty } from "../../../../redux/assessmentState"
import DatePicker from "react-datepicker"




export const DateComponent = (props: QuestionSubcomponentProps) => {

	const {
		assessmentId,
		assessmentIndex,
		questionaireId,
		questionaireIndex,
		questionaireIsDirty,
		id,
		prompt,
		savedAnswer,
		isExtra,
		outerIndexOfExtra,
		innerIndexOfExtra,
		questionPath
	} = props

	const dispatch = useAppDispatch()

	const dateAnswer = useRef<Date>()

	const currentYear = new Date().getFullYear()
	const startYear = currentYear - 100  // 100 years ago
	const endYear = currentYear     // 18 years ago to ensure adult users

	const years = Array.from({ length: endYear - startYear + 1 }, (v, k) => k + startYear)
	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	]




	const handleSetAnswerContent = () => {
		// here is where we update redux state with the response text
		console.log("date answer in submit: ", dateAnswer.current)
		const answerData: SetAnswerOfflineParams = {
			answer: {
				question: id,
				assessment: assessmentId,
				questionaire: questionaireId,
				date_content: dateAnswer?.current?.toISOString().split("T")[0]  //.toLocaleDateString().replaceAll("/", "-")
			},
			questionPath,
			assessmentIndex,
			questionaireIndex
		}

		if (isExtra && !(typeof outerIndexOfExtra === "undefined") && !(typeof innerIndexOfExtra === "undefined")) {
			answerData.answer["outer_index_of_extra"] = outerIndexOfExtra
			answerData.answer["inner_index_of_extra"] = innerIndexOfExtra
		}

		if (savedAnswer?.id) {
			answerData.answer.id = savedAnswer.id
		}
		dispatch(setAnswerOffline(answerData))

		if ((!questionaireIsDirty) && !(typeof assessmentIndex === "undefined")) {
			dispatch(setQuestionaireIsDirty({ assessmentIndex, questionaireIndex, isDirty: true }))
		}
	}
	const initialValue = savedAnswer?.date_content ? new Date(savedAnswer.date_content) : dateAnswer.current

	const [selectedDate, setSelectedDate] = useState<Date | null>(initialValue || new Date())


	
	const handleDateChange = (date: Date | null) => {
		if (date) {
			setSelectedDate(date)
			dateAnswer.current = date
			handleSetAnswerContent()
		}
	}

	return (
		<View style={styles.questionContainer}>
			{
				Platform.OS == "web" ?
					<DatePicker
						renderCustomHeader={({
							date,
							changeYear,
							changeMonth,
							decreaseMonth,
							increaseMonth,
							prevMonthButtonDisabled,
							nextMonthButtonDisabled,
						}) => (
							<div
								style={{
									margin: 10,
									display: "flex",
									justifyContent: "center",
								}}
							>
								<button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
									{"<"}
								</button>
								<select
									value={date.getFullYear()}
									onChange={({ target: { value } }) => changeYear(Number(value))}
								>
									{years.map((option) => (
										<option key={option} value={option}>
											{option}
										</option>
									))}
								</select>

								<select
									value={months[date.getMonth()]}
									onChange={({ target: { value } }) =>
										changeMonth(months.indexOf(value))
									}
								>
									{months.map((option) => (
										<option key={option} value={option}>
											{option}
										</option>
									))}
								</select>

								<button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
									{">"}
								</button>
							</div>
						)}
						selected={selectedDate}
						onChange={handleDateChange}
						popperPlacement="top-start"
					/>
					:
					<MobileDatePicker
						style={{ alignSelf: "flex-start", padding: 5 }}
						value={initialValue || new Date()}
						mode="date"
						onChange={(event) => {
							const date = new Date(event.nativeEvent.timestamp || 0)
							dateAnswer.current = date
							handleSetAnswerContent()
						}}
					/>
			}
		</View>
	)
}