import { Button, Input, Card } from "@rneui/themed"
import { useTranslation } from "react-i18next"
import { PhysicianComponentProps } from "../../../@types/components/physician"
import { useAppDispatch } from "../../redux/hooks"
import { removePhysicianAtIndex, setPhysicianFaxNumber, setPhysicianFirstName, setPhysicianLastName, setPhysicianOfficeAddressLine1, setPhysicianOfficeAddressLine2, setPhysicianOfficeAddressPostalCode, setPhysicianOfficeCity, setPhysicianOfficeCountry, setPhysicianOfficeStateProvince, setPhysicianPhoneNumber, setPhysicianSpecialty } from "../../redux/medicalProfileState"
import { Address } from "../Address/Address"
import { styles } from "./styles"


export const PhysicianComponent = (props: PhysicianComponentProps) => {
	const { physician, addressErrors, index, medErrors } = props


	const { t } = useTranslation(["translation", "screens"])
	const dispatch = useAppDispatch()
	const physicianProperty = `physician${index + 1}`

	return (
		<Card>
			<Card.Title h3>Physician #{index+1}</Card.Title>
			<Card.Divider/>
			<Input
				style={styles.input}
				label={t("physicianFirstName")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={physician.first_name}
				onChangeText={(name) => dispatch(setPhysicianFirstName({index, name}))}
				errorMessage={medErrors?.[`${physicianProperty}_first_name`]?.join("\n")}/>
			<Input
				style={styles.input}
				label={t("physicianLastName")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={physician.last_name}
				onChangeText={(name) => dispatch(setPhysicianLastName({index, name}))}
				errorMessage={medErrors?.[`${physicianProperty}_last_name`]?.join("\n")}/>
			<Input
				style={styles.input}
				label={t("physicianSpecialty")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={physician.specialty}
				onChangeText={(specialty) => dispatch(setPhysicianSpecialty({index, specialty}))}
				errorMessage={medErrors?.[`${physicianProperty}_specialty`]?.join("\n")}/>
			
			<Input
				style={styles.input}
				label={t("physiciansPhoneNumber")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={physician.phone_number}
				onChangeText={(phone) => dispatch(setPhysicianPhoneNumber({index, phone}))}
				errorMessage={medErrors?.[`${physicianProperty}_phone_number`]?.join("\n")}/>
			<Input
				style={styles.input}
				label={t("faxNumber")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={physician.fax_number}
				onChangeText={(fax) => dispatch(setPhysicianFaxNumber({index, fax}))}
				errorMessage={medErrors?.[`${physicianProperty}_fax_number`]?.join("\n")}/>
			<Address 
				index={index} 
				office_address={physician.office_address} 
				addressErrors={addressErrors} 
				medErrors={medErrors}
			/>
			{ index > 0 ?
				<Button
					title={`${t("removePhysician")} #${index+1}` || "Remove This Physician"}
					onPress={() => dispatch(removePhysicianAtIndex(index))}
					buttonStyle={styles.button}
					titleStyle={styles.buttonText}
				/> : null
			}
		</Card>
	)
}
