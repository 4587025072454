import { Input, Text } from "@rneui/themed"
import { useEffect, useState } from "react"
import { View } from "react-native"
import { QuestionSubcomponentProps } from "../../../../../@types/components/question"
import { SetAnswerOfflineParams } from "../../../../../@types/redux/Answer"
import { setAnswerOffline } from "../../../../redux/answerState"
import { setQuestionaireIsDirty } from "../../../../redux/assessmentState"
import { useAppDispatch } from "../../../../redux/hooks"
import { styles } from "../../styles"



export const FloatComponent = (props: QuestionSubcomponentProps) => {

	const {
		assessmentId,
		assessmentIndex,
		questionaireId,
		questionaireIndex,
		questionaireIsDirty,
		id,
		savedAnswer,
		isExtra,
		outerIndexOfExtra,
		innerIndexOfExtra,
		questionPath
	} = props

	const dispatch = useAppDispatch()

	const [floatAnswer, setFloatAnswer] = useState<number>()
	const [floatInputError, setFloatInputError] = useState<string | undefined>()

	// Initialize state with the saved answer, if it exists
	useEffect(() => {
		if (savedAnswer?.float_content) {
			setFloatAnswer(savedAnswer.float_content)
		}
	}, [savedAnswer])

	const validateNumberAnswer = (inputString: string) => {
		const inputNum: number | typeof NaN = Number(inputString)

		if (!isNaN(inputNum)) {
			setFloatAnswer(parseFloat(inputNum.toFixed(2)))
			setFloatInputError(undefined)
		} else {
			setFloatInputError("Please submit a valid decimal number")
		}
	}

	const handleSetAnswerContent = () => {
		// here is where we update redux state with the response text
		const answerData: SetAnswerOfflineParams = {
			answer: {
				question: id,
				assessment: assessmentId,
				questionaire: questionaireId
			},
			questionPath,
			assessmentIndex,
			questionaireIndex,
		}

		if (isExtra && !(typeof outerIndexOfExtra === "undefined") && !(typeof innerIndexOfExtra === "undefined")) {
			answerData.answer["outer_index_of_extra"] = outerIndexOfExtra
			answerData.answer["inner_index_of_extra"] = innerIndexOfExtra
		}

		if (floatAnswer) answerData.answer.float_content = floatAnswer
		
		dispatch(setAnswerOffline(answerData))

		if ((!questionaireIsDirty) && !(typeof assessmentIndex === "undefined")) {
			dispatch(setQuestionaireIsDirty({ assessmentIndex, questionaireIndex, isDirty: true }))
		}
	}

	return (
		<View style={styles.questionContainer}>
			<Input
				placeholder="Input Response"
				style={styles.inputInnerContainer}
				value={floatAnswer?.toString()}
				onChangeText={(input) => validateNumberAnswer(input)}
				onBlur={() => !floatInputError && handleSetAnswerContent()}
				keyboardType="decimal-pad"
				errorMessage={floatInputError}
			/>
		</View>
	)
}