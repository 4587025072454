import { useNavigation, useRoute } from "@react-navigation/native"
import { CheckBox, Text } from "@rneui/themed"
import { Feather } from "@expo/vector-icons"
import { useEffect } from "react"
import { View } from "react-native"
import { LoginScreenNavigationProp } from "../../../@types/navigation/AuthNavigator"
import { getDiseases, setSelectedDisease } from "../../redux/diseaseState"
import { AppState } from "../../redux/store"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { styles } from "./styles"
import { useTheme } from "@rneui/themed"
import { BasePage } from "../../components/BasePage/BasePage"
import * as Network from "expo-network"
import { DiseaseType } from "../../../@types/redux/disease"
import { showToast } from "../../../toast"
import { updateActiveDisease } from "../../redux/authState"
import { resetSubmissionStatusForQuestionairesInAssessment, syncAssessments } from "../../redux/assessmentState"
import { clearAnswersForMultipleAssessments } from "../../redux/answerState"



export const SettingsScreen = () => {
	const { theme } = useTheme()

	const navigation = useNavigation<LoginScreenNavigationProp>()
	const dispatch = useAppDispatch()
	const diseaseState = useAppSelector((state: AppState) => state.diseaseState)
	const { assessments } = useAppSelector((state: AppState) => state.assessmentState)

	const getCurrentOrNextAssementIndex = () => {
		const currentDate = new Date()
		const currentOrNextAssessment = assessments.findIndex(assessment => {
			const assessmentStartDate = new Date(assessment.start_date)
			const assessmentEndDate = new Date(assessment.end_date)

			const isCurrentAssessment = (assessmentStartDate <= currentDate) && (assessmentEndDate >= currentDate)
			if (isCurrentAssessment) return true
			
			const sevenMonthsFromNow = new Date()
			sevenMonthsFromNow.setMonth(currentDate.getMonth() + 7)
			const isNextAssessment = (assessmentStartDate <= sevenMonthsFromNow) && (assessmentEndDate >= sevenMonthsFromNow)
			if (isNextAssessment) return true
		})
		return currentOrNextAssessment == -1 ? assessments.length - 1 : currentOrNextAssessment
	}

	useEffect(() => {
		dispatch(getDiseases())
	}, [])



	const route = useRoute()


	const handleSetSelectedDisease = async (disease: DiseaseType) => {

		const { isConnected, isInternetReachable } = await Network.getNetworkStateAsync()
		console.log("route name: ", route.name)
		if (route.name == "Eligibility") {
			dispatch(setSelectedDisease(disease))
			return
		}
		if (!isConnected && !isInternetReachable) {
			showToast({ message: "Must be connected to internet to change disease. Please try again when you are connected to the internet", kind: "failure" })
			return
		}
		try {
			await dispatch(updateActiveDisease({ disease })).unwrap()
			dispatch(setSelectedDisease(disease))

			const indicesToReset = assessments.slice(getCurrentOrNextAssementIndex()).map((_, i) => i)
			console.log("indicdes to reset: ", indicesToReset)
			dispatch(clearAnswersForMultipleAssessments({ assessmentIndices: indicesToReset }))			
			dispatch(syncAssessments())
			dispatch(resetSubmissionStatusForQuestionairesInAssessment({ assessmentIndex: indicesToReset[0] }))
			showToast({
				message: "Updated disease successfully!",
				kind: "success"
			})
		} catch (err) {
			showToast({
				message: `Error updating selected disease. Error: ${err}`,
				kind: "failure"
			})
		}
	}

	return (
		<BasePage>
			<Text style={{ textAlign: "center", marginTop: 10, marginBottom: 18, fontSize: 16, color: "#575857" }}>If you diagnosis changes after you join the study, please change it here accordingly:</Text>
			{
				diseaseState.available.map(disease => {
					return (
						<CheckBox key={disease.id}
							title={disease.name}
							checked={disease.id == diseaseState.selected?.id}
							checkedIcon={
								<Feather name='check-circle' size={25} color='green' />
							}
							uncheckedIcon={
								<Feather
									name="circle"
									color="grey"
									size={25}
								/>
							}
							onPress={async () => await handleSetSelectedDisease(disease)}
						/>
					)
				})
			}
		</BasePage>
	)
}
