/* eslint-disable @typescript-eslint/no-var-requires */
import { Platform, Image, Pressable } from "react-native"
import { Text } from "@rneui/themed"
import { View } from "react-native"
import { useGoogleLogin, CodeResponse } from "@react-oauth/google"
import { useState } from "react"
import { useAppDispatch } from "../../redux/hooks"
import { logUserInWithGoogle } from "../../redux/authState"
import { showToast } from "../../../toast"


const GoogleWebLogin = () => {

	const dispatch = useAppDispatch()

	const handleGoogleLogin = async (res: CodeResponse) => {
		const { code } = res
		await dispatch(logUserInWithGoogle({ code }))
	}


	const handleGoogleLoginError = () => {
		console.log("something went wrong logging into google")
		showToast({ message: "Failed to log in with google", kind:"failure" })
	}

	const doGoogleLogin = useGoogleLogin({
		onSuccess: handleGoogleLogin,
		onError: handleGoogleLoginError,
		flow: "auth-code",
	})

	return (
		<View style={{
			justifyContent: "center", 
			alignContent: "center",
			alignItems: "center"
		}}>
			<Pressable onPress={doGoogleLogin}>
				<Image 
					source={require("../../../assets/images/web_google_sign_in.png")}
					style={{width: 200, height: 50}}
				/>	
			</Pressable>
		</View>
		
	)
}

const GoogleMobileLogin = () => {

	const { GoogleSignin, GoogleSigninButton, statusCodes } = require("@react-native-google-signin/google-signin") 
	const dispatch = useAppDispatch()
	const [disabled, setDisabled] = useState(false)
	const handleGoogleLogin = async () => {
		try {
			await GoogleSignin.hasPlayServices()
			const { serverAuthCode } = await GoogleSignin.signIn()
			await dispatch(logUserInWithGoogle({ code: serverAuthCode || "" }))
		} catch (err) {
			// @ts-ignore
			if (err?.code === statusCodes.IN_PROGRESS) {
				setDisabled(true)
			}
			showToast({ message: `Error logging in. Error: ${JSON.stringify(err)}`, kind: "failure" })
		}
		console.log("logging into google from android")
	}
	return (
		<View>
			<GoogleSigninButton 
				size={GoogleSigninButton.Size.Wide}
				onPress={handleGoogleLogin}
				disabled={disabled}
			/>
		</View>
	)
}

const GoogleAndroidLogin = () => {
	return (
		<View>
			{<GoogleMobileLogin />}
		</View>
	)
}

const GoogleIOSLogin = () => {
	return (
		<View>
			<GoogleMobileLogin />
		</View>
		
	)
}

export const GoogleLogin = () => {
	if (Platform.OS === "ios") {
		return <GoogleIOSLogin />
	} else if (Platform.OS === "android") {
		return <GoogleAndroidLogin />
	} else if (Platform.OS === "web") {
		return <GoogleWebLogin />
	} else {
		// you probably won't end up here unless you support another platform!
		return null
	}

}
