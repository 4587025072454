import { Draft } from "@reduxjs/toolkit"
import * as Network from "expo-network"
import * as FileSystem from "expo-file-system"
import { Platform } from "react-native"
import { ErrorResponseData } from "../../@types/axios/error"
import { AnswerType } from "../../@types/redux/answer"
import { BaseState, Errors, SendErrorsToStateAction } from "../../@types/redux/util"
import * as _ from "lodash"


import { INTERNET_UNRECHABLE_MESSAGE } from "../../constants/errorMessages"
import { QuestionaireType } from "../../@types/redux/questionaire"
import { makeRootPath } from "../components/util"
import { Address, MedicalProfile, Physician, PhysicianSubmitData } from "../../@types/redux/medicalProfile"
import { formatDate } from "../../util"
import i18n from "../../i18n.config"


export const defaultInitialErrorState: Errors = {
	general: []
}

export const checkForInternetAccess = async () => {
	const { isConnected, isInternetReachable } = await Network.getNetworkStateAsync()

	if (!(isConnected && isInternetReachable)) {
		throw new Error(INTERNET_UNRECHABLE_MESSAGE)
	}
}

export const sendErrorsToState = <T extends BaseState>(state: Draft<T>, action: SendErrorsToStateAction) => {
	const currentLang = i18n.language

	if (action.error.message) {
		state.errors.general.push(action.error.message)
	}
	if (action.payload?.non_field_errors) {
		const previousErrors = state.errors.general
		state.errors.general = [...previousErrors, ...action.payload.non_field_errors]
	}
	if (action.payload) {
		Object.entries(action.payload as ErrorResponseData).forEach(([field, errors]) => {
			// Some error's end up nested inside a translations object and language object.
			// if field === translations, we're trying to get the actual errors we want 
			// to set them correctly in state
			if (field === "translations") {
				Object.entries(errors[currentLang]).forEach(([innerField, innerErrors]) => {
					const field = innerField
					const errorMessage = innerErrors
					state.errors[field] = errorMessage
				})
				// The same thing is happening here. Physician errors are not correctly set in state. We 
				// Need to key into the physicians object, loop through the number of physicians and 
				// extract the actual errors we want to set in state
			} else if (field === "physicians") {
				Object.entries(errors).forEach(([innerField, innerErrors], index) => {
					// We loop through each individual physician error object and place it into a variable errorObj
					const errorObj = innerErrors

					Object.entries(errorObj).forEach(([innerField, innerErrors]) => {
						// We loop through the physician error object
						// We will encounter more nested objects that contain the errors we want
						// We will need to traverse either a translations object or an office_address object
						const field = innerField
						const errors = innerErrors

						if (field === "translations") {
							Object.entries(errors[currentLang]).forEach(([innerField, innerErrors]) => {
								// console.log("THIS IS MY INNER FIELD: ", innerField)
								// console.log("THIS IS MY INNER ERRORS: ", innerErrors)
								const field = innerField
								const errors = innerErrors
								state.errors[`physician${index + 1}_${field}`] = errors
							})
						} else if (field === "office_address") {
							Object.entries(errors["translations"][currentLang]).forEach(([innerField, innerErrors]) => {
								// console.log("THIS IS MY INNER FIELD: ", innerField)
								// console.log("THIS IS MY INNER ERRORS: ", innerErrors)
								const field = innerField
								const errors = innerErrors
								state.errors[`physician${index + 1}_${field}`] = errors
							})
						} else {
							// console.log("AFTER ALL THE IF CHECKS")
							// console.log("FIELD: ", field)
							// console.log("ERROR MESSAGE: ", errors)
							state.errors[`physician${index + 1}_${field}`] = errors
						}

					})
				})
			} else {
				state.errors[field] = errors
			}
		})
	}
}

const getAnswerPaths = (answer: AnswerType, path: string): string[] => {
	const paths = [path]

	if (answer?.follow_up_answers) {
		paths.push(
			...answer.follow_up_answers.flatMap(
				(followUpAnswer, i) => getAnswerPaths(followUpAnswer, `${path}.follow_up_answers[${i}]`)
			)
		)
	}
	// do not need to check extra answers because they are always answers to the parent's follow up questions, which checking follow up answers will verify
	return paths
}


export const verifyQuestionsExistForAllQuestionsInQuestionaire = (questionaireAnswers: AnswerType[], questionaire: QuestionaireType, assessmentIndex: number, questionaireIndex: number, useRootPath=true) => {

	const rootPath = makeRootPath(assessmentIndex, questionaire.id)
	const pathsToDelete: string[] = []
	if (questionaireAnswers) {
		questionaireAnswers?.forEach((answer, i) => {
			const initialPath = `[${i}]`
			const paths = getAnswerPaths(answer, initialPath)
			const toDelete = paths.filter(path => {
				const hasPath = _.has(questionaire.questions, path.replaceAll("follow_up_answers", "follow_up_questions"))
				if (!hasPath) {
					return true
				}
				const question = _.get(questionaire.questions, path.replaceAll("follow_up_answers", "follow_up_questions"))

				if (!(question?.id === answer?.question)) {
					if (answer?.follow_up_answers) {
						const questionAndAnswerExists = answer.follow_up_answers.some(answer => answer.question === question.child_question.id)
						if (!questionAndAnswerExists) {
							return true
						}
					}
				}
			})
			pathsToDelete.push(...toDelete.map(path => useRootPath ? `${rootPath}${path}` : path))
		})
	}
	
	return pathsToDelete
}


export const handleDownloadDocument = async (downloadUrl: string, filename: string) => {
	console.log("download url in util func: ", downloadUrl)
	if (Platform.OS === "web") { // this smells, seems kind of hacky. probably a better way
		const link = document.createElement("a")
		link.href = downloadUrl
		link.target = "_blank"
		link.rel = "noopener noreferrer"
		link.click()
	} else {
		const downloadLocation = FileSystem.documentDirectory + filename
		const dirInfo = await FileSystem.getInfoAsync(downloadLocation)
		console.log("dir info: ", dirInfo)
		if (!dirInfo.exists) {
			await FileSystem.makeDirectoryAsync(downloadLocation, { intermediates: true })
		}
		const downloadResumable = FileSystem.createDownloadResumable(
			downloadUrl,
			downloadLocation,
			{},
			// () callback function. could use for progress or for alert when complete
		)
		const result = await downloadResumable.downloadAsync()
		console.log("native download result: ", result)
	}

}

export const createPhysicianData = (physicians: Physician[], lang: string) => {
	return physicians.reduce((acc: PhysicianSubmitData[], phys) => {
		const data: PhysicianSubmitData = {
			translations: {
				[lang]: {
					first_name: phys.first_name,
					last_name: phys.last_name,
					specialty: phys.specialty,
					fax_number: phys.fax_number || "",
				}
			},
			id: phys.id,
			phone_number: phys.phone_number,
			office_address: createAddressData(phys.office_address, lang)
		}
		acc.push(data)
		return acc
	}, [])
}

export const createAddressData = (address: Address, lang: string) => {
	return {
		translations: {
			[lang]: {
				...address
			}
		},
		id: address.id
	}
}

export const createProfileData = (profile: MedicalProfile, lang: string, userId?: number) => {
	const data: Record<any, any> = {
		translations: {
			[lang]: {
				first_name: profile.first_name,
				last_name: profile.last_name,
				gender: profile.gender,
				date_of_birth: formatDate(profile.date_of_birth),
				ethnicity: profile.ethnicity,
				race: profile.race,
				employment_status: profile.employment_status,
				occupation_title: profile.occupation_title,
				heard_about: profile.heard_about
			}
		},
		id: profile.id,
		medical_release_signature: profile.medical_release_signature,
		phone_number: profile.phone_number,
		physicians: createPhysicianData(profile.physicians, lang)
	}
	if (userId) {
		data.user = userId
	}
	return data
}
