import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios from "axios"
import { ErrorResponseData } from "../../@types/axios/error"
import { DiseaseIntroduction, DiseaseState, DiseaseType } from "../../@types/redux/disease"
import { backendAPIClient } from "../axios/axios"
import { AppState } from "./store"
import {
	checkForInternetAccess,
	defaultInitialErrorState,
	sendErrorsToState,
} from "./util"

const internalInitialState: DiseaseState = {
	available: [],
	isLoading: false,
	errors: {
		general: [],
	},
}

export const diseaseSlice = createSlice({
	name: "diseaseState",
	initialState: internalInitialState,
	reducers: {
		setSelectedDisease: (state, action: PayloadAction<DiseaseType>) => {
			console.log("setting selected disease: ", action.payload)
			state.selected = action.payload
		},
		resetDisease: () => internalInitialState,
		resetErrors: (state, action: PayloadAction<undefined>) => {
			state.errors = defaultInitialErrorState
		},
		updateSelectedDiseaseIntroduction: (state, action: PayloadAction<DiseaseIntroduction | undefined>) => {
			if (state.selected) {
				state.selected.introduction = action.payload
			}
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getDiseases.pending, (state, action) => {
			state.isLoading = true
		}),
		builder.addCase(getDiseases.fulfilled, (state, action) => {
			state.isLoading = false
			state.available = action.payload
		}),
		builder.addCase(getDiseases.rejected, (state, action) => {
			state.isLoading = false
			sendErrorsToState(state, action)
		})
	},
})

export const getDiseases = createAsyncThunk<
  Array<DiseaseType>,
  undefined,
  {
    state: AppState;
    rejectValue: ErrorResponseData;
  }
>(
	"getDiseases",
	async (_, { dispatch, rejectWithValue }): Promise<Array<DiseaseType>> => {
		await checkForInternetAccess()
		dispatch(resetErrors())

		try {
			const diseasesUrl = "api/v1/diseases/?verbose=true"
			const response = await backendAPIClient.get(diseasesUrl)
			return response.data
		} catch (error) {
			if (axios.isAxiosError(error)) {
				throw rejectWithValue(error.response?.data)
			} else {
				throw error
			}
		}
	}
)

// actions are generated by the createSlice function
export const { setSelectedDisease, resetDisease, resetErrors, updateSelectedDiseaseIntroduction } =
  diseaseSlice.actions

export default diseaseSlice.reducer
