import { Button, Card, Input } from "@rneui/themed"
import { useNavigation } from "@react-navigation/native"
import { useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { signUpNewUser } from "../../../redux/authState"
import { SignUpScreenNavigationProp } from "../../../../@types/navigation/AuthNavigator"
import { AppState } from "../../../redux/store"
import { styles } from "./styles"
import { AuthFooterComponent } from "../../../components/AuthFooter/AuthFooter"
import { useTranslation } from "react-i18next"
import { useTheme } from "@rneui/themed"
import { ScrollView } from "react-native-gesture-handler"
import { LoadingSpinner } from "../../../components/Loading/LoadingSpinner"


export const SignUpScreen = () => {
	const { theme } = useTheme()


	const dispatch = useAppDispatch()

	const authState = useAppSelector((state: AppState) => state.authState)
	const signUpLoading = useAppSelector((state: AppState) => state.authState.isLoading)

	// needed for actual user account
	const [password1, setPassword1] = useState("")
	const [password2, setPassword2] = useState("")
	const [email, setEmail] = useState("")

	const userId = useRef(0)

	const navigation = useNavigation<SignUpScreenNavigationProp>()

	const { t } = useTranslation(["translation", "screens"])

	const handleSignUp = async () => {

		const signUpData = { password1, password2, email }
		try {

			const createUserResponse = await dispatch(signUpNewUser(signUpData)).unwrap()

			userId.current = createUserResponse.data.user.id
			navigation.navigate("MedicalProfile", {userId: userId.current})
		} catch (error) {
			console.log("an error ocurred: ", error)
		}
	}

	return (
		<>
			<LoadingSpinner isLoading={signUpLoading} message="Loading"/>
			<ScrollView style={[styles.container, {backgroundColor: theme.colors.white}]}>
				<Card containerStyle={styles.cardOuterContainer} wrapperStyle={styles.cardInnerContainer}>
					<Card.Title h3>{t("userAccountInformation")}</Card.Title>
					<Card.Divider/>
					<Input
						style={{paddingHorizontal: 8 }}
						label={t("email")}
						value={email}
						onChangeText={setEmail}
						autoCapitalize="none"
						textContentType="emailAddress"
						autoCorrect={false}
						keyboardType="email-address"
						autoComplete="email"
						errorMessage={authState.errors.email?.join("\n")}/>
					<Input
						style={{paddingHorizontal: 8 }}
						label={t("password")}
						value={password1}
						onChangeText={setPassword1}
						secureTextEntry={true}
						errorMessage={authState.errors.password1?.join("\n")}/>
					<Input
						style={{paddingHorizontal: 8 }}
						label={t("passwordAgain")}
						value={password2}
						onChangeText={setPassword2}
						secureTextEntry={true}
						errorMessage={authState.errors.password2?.join("\n")}/>
				</Card>
				<Button
					title="Submit"
					onPress={handleSignUp}
					style={styles.button}
				/>
				<AuthFooterComponent />
			</ScrollView>
		</>
	)
}
