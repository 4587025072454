/* eslint-disable @typescript-eslint/no-var-requires */
import "react-native-gesture-handler"
import { StatusBar } from "expo-status-bar"
import { Text } from "@rneui/base"
import { ThemeProvider } from "@rneui/themed"
import { SafeAreaProvider } from "react-native-safe-area-context"
import store, { appStorePersistor } from "./src/redux/store"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import RootNavigator from "./src/navigation/RootNavigator"
import { upittTheme } from "./theme/rootTheme"
import { ToastProvider } from "react-native-toast-notifications"
import Toast from "react-native-toast-notifications"
import i18n from "./i18n.config"
import { I18nextProvider } from "react-i18next"
import { Platform } from "react-native"
import { GoogleOAuthProvider } from "@react-oauth/google"
import Constants from "expo-constants"




const BaseApp = (
	<ToastProvider offsetTop={95} offsetBottom={50}>
		<Provider store={store}>
			<PersistGate
				loading={<Text>Loading...</Text>}
				persistor={appStorePersistor}
			>
				<I18nextProvider i18n={i18n}>
					<SafeAreaProvider>
						<ThemeProvider theme={upittTheme}>
							<RootNavigator />
							<StatusBar style="light" />
							{/* @ts-ignore */}
							<Toast
								ref={(ref) => (ref ? (global["toast"] = ref) : undefined)}
							/>
						</ThemeProvider>
					</SafeAreaProvider>
				</I18nextProvider>
			</PersistGate>
		</Provider>
	</ToastProvider>
)



console.disableYellowBox = true
const ogWarn = console.warn
console.warn = (args) => { console.log("hw") }

export default function App() {

	if (Platform.OS === "web") {
		const googleWebClientId = process.env.EXPO_PUBLIC_GOOGLE_WEB_CLIENT_ID || ""
		// const googleWebClientId = Constants.manifest.extra.EXPO_PUBLIC_GOOGLE_WEB_CLIENT_ID || ""
		console.log("google web client id: ", googleWebClientId)
		return (
			<GoogleOAuthProvider clientId={googleWebClientId}>
				{BaseApp}
			</ GoogleOAuthProvider>
		)
	}	else {
		// @ts-ignore
		const { GoogleSignin } = require("@react-native-google-signin/google-signin")
		GoogleSignin.configure()
		return BaseApp
	}
}

