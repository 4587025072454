import { Text } from "@rneui/themed"
import { AntDesign } from "@expo/vector-icons"
import { SubmissionStatusProps } from "../../../@types/components/assessment"






export const SubmissionStatus = (props: SubmissionStatusProps) => {

	const { 
		submitted,
		answeredAndAvailable,
		isDirty
	} = props

	const [
		numberOfQuestionsAnswered,
		numberOfQuestionsAvailable
	] = answeredAndAvailable

	const formattedAnswerCount = `${numberOfQuestionsAnswered}/${numberOfQuestionsAvailable}`

	switch (submitted) {
	case true: // TODO might need to expand this to account for changing an answer to a question.
		// because question count may be the same but answer content may be different
		// user should be aware that their questionaire answers are 'dirty'. when submitted, questionaire should be marked as 'clean'
		if (isDirty) {
			return (
				<Text>{formattedAnswerCount} Previously Submitted, Changed Answers {<AntDesign name="check" size={24} color="green"/>}</Text>
			)
		} else {
			return (
				<Text>{formattedAnswerCount} Submitted {<AntDesign name="check" size={24} color="green"/>}</Text>
			)
		}

	case undefined:
		if (numberOfQuestionsAnswered > 0) {
			return (
				<Text>{formattedAnswerCount} Not Submitted {<AntDesign name="question" size={24} color="yellow"/>}</Text>
			)
		}
		return (
			<Text>{formattedAnswerCount} Not Started {<AntDesign name="exclamation" size={24} color="red"/>}</Text>
		)

	case false:
		return (
			<Text>{formattedAnswerCount} Not Submitted {<AntDesign name="question" size={24} color="yellow"/>}</Text>
		)
	}
}
